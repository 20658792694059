.ticket-name-container {
  display: grid;
  grid-template-columns: auto 1fr; /* Icon auto-sized, text takes remaining space */
  align-items: center;
  gap: 10px;
  max-width: 100%; /* Ensures it does not overflow parent */
}

.ticket-icon-container {
  border-radius: 6px;
  border: 1px solid var(--Grey-5, #ebeaea);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 28px;
  width: 28px;
}

.ticket-details {
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: flex-start;
  justify-content: center;
  min-width: 0;
  overflow: hidden;
  max-width: 100%;
}

.ticket-details a {
  color: var(--Grey-1, #3d3d3d);
  font-family: 'HEX Franklin v0.2';
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  text-decoration: underline;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  max-width: 100%;
}

.ticket-details .id {
  color: var(--Grey-3, #7f7f7f);
  font-family: 'HEX Franklin v0.2';
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
  padding: 0;
  margin: 0;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}
